import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { childSave, parentAllList } from '../../../../store/actions/menuActions'

const ChildCreate = () => {
  const [name, setname] = useState('')
  const [access, setaccess] = useState('')
  const [parent, setparent] = useState('')

  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(parentAllList())
  }, [dispatch])

  const dataparentAllList = useSelector(state => state.parentAllList)
  const { listsParent } = dataparentAllList

  const datachildSave = useSelector(state => state.childSave)
  const { validation } = datachildSave

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(childSave(name, parent, access, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Child</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                    name="name" 
                    required 
                    onChange={(e) => setname(e.target.value)}
                  />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['name']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label>Access</label>
                  <select 
                    className={ validation.length !== 0 && validation['access'] ? 'form-control parsley-error' : 'form-control' }
                    required 
                    onChange={(e) => setaccess(e.target.value)}>
                    <option disabled selected value="">Select Access</option>
                    <option value="MY">MY</option>
                    <option value="MYCRM">MYCRM</option>
                  </select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['access']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                {
                  (access !== '' ?
                  
                    <div className="form-group">
                      <label>Parent </label>
                      <select 
                        className={ validation.length !== 0 && validation['access'] ? 'form-control parsley-error' : 'form-control' }
                        required onChange={(e) => setparent(e.target.value)}>
                        <option disabled selected value="">Select Parent</option>
                        {
                          listsParent.map((item, index) => {
                            if (item.access === access) {
                              return(
                                <option key={index} value={item.id}>{item.name}</option>
                              )
                            }
                          })
                        }
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['parent']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  :
                    null
                  )
                }
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChildCreate
