import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/actions/authActions'

const Navbar = () => {

  let dispatch = useDispatch()
  
  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout())
  }

  return (
    <div className="top_nav">
      <div className="nav_menu">
          <div className="nav toggle">
            <a id="menu_toggle"><i className="fa fa-bars" style={{ color: 'white' }}></i></a>
          </div>
          <nav className="nav navbar-nav">
          <ul className=" navbar-right">
            <li className="nav-item dropdown open" style={{ paddingLeft: '15px' }}>
              <a href="#!" className="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                {/* <img src="images/img.jpg" alt="" /> */}
                {userInfo.user.name}
              </a>
              <div className="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown">
                {/* <a className="dropdown-item"  href="#!"> Profile</a>
                <a className="dropdown-item"  href="#!">
                  <span className="badge bg-red pull-right">50%</span>
                  <span>Settings</span>
                </a> */}
                {/* <a className="dropdown-item"  href="#!">Help</a> */}
                <button className="dropdown-item" onClick={(e) => handleLogout(e)} ><i className="fa fa-sign-out pull-right"></i> Log Out</button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
