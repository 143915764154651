import { CHILD_DELETE_FAIL, CHILD_DELETE_REQUEST, CHILD_DELETE_SUCCESS, CHILD_EDIT_FAIL, CHILD_EDIT_REQUEST, CHILD_EDIT_SUCCESS, CHILD_LIST_FAIL, CHILD_LIST_REQUEST, CHILD_LIST_SUCCESS, CHILD_SAVE_FAIL, CHILD_SAVE_REQUEST, CHILD_SAVE_SUCCESS, CHILD_SAVE_VALIDATION, CHILD_UPDATE_FAIL, CHILD_UPDATE_REQUEST, CHILD_UPDATE_SUCCESS, CHILD_UPDATE_VALIDATION, MENU_LIST_FAIL, MENU_LIST_REQUEST, MENU_LIST_SUCCESS, PARENT_ALL_LIST_FAIL, PARENT_ALL_LIST_REQUEST, PARENT_ALL_LIST_SUCCESS, PARENT_DELETE_FAIL, PARENT_DELETE_REQUEST, PARENT_DELETE_SUCCESS, PARENT_EDIT_FAIL, PARENT_EDIT_REQUEST, PARENT_EDIT_SUCCESS, PARENT_LIST_FAIL, PARENT_LIST_REQUEST, PARENT_LIST_SUCCESS, PARENT_SAVE_FAIL, PARENT_SAVE_REQUEST, PARENT_SAVE_SUCCESS, PARENT_SAVE_VALIDATION, PARENT_UPDATE_FAIL, PARENT_UPDATE_REQUEST, PARENT_UPDATE_SUCCESS, PARENT_UPDATE_VALIDATION, ROLE_ALL_LIST_FAIL, ROLE_ALL_LIST_REQUEST, ROLE_ALL_LIST_SUCCESS, ROLE_MYCRM_DELETE_FAIL, ROLE_MYCRM_DELETE_REQUEST, ROLE_MYCRM_DELETE_SUCCESS, ROLE_MYCRM_EDIT_FAIL, ROLE_MYCRM_EDIT_REQUEST, ROLE_MYCRM_EDIT_SUCCESS, ROLE_MYCRM_LIST_FAIL, ROLE_MYCRM_LIST_REQUEST, ROLE_MYCRM_LIST_SUCCESS, ROLE_MYCRM_SAVE_FAIL, ROLE_MYCRM_SAVE_REQUEST, ROLE_MYCRM_SAVE_SUCCESS, ROLE_MYCRM_SAVE_VALIDATION, ROLE_MYCRM_UPDATE_FAIL, ROLE_MYCRM_UPDATE_REQUEST, ROLE_MYCRM_UPDATE_SUCCESS, ROLE_MYCRM_UPDATE_VALIDATION, ROLE_MY_DELETE_FAIL, ROLE_MY_DELETE_REQUEST, ROLE_MY_DELETE_SUCCESS, ROLE_MY_EDIT_FAIL, ROLE_MY_EDIT_REQUEST, ROLE_MY_EDIT_SUCCESS, ROLE_MY_LIST_FAIL, ROLE_MY_LIST_REQUEST, ROLE_MY_LIST_SUCCESS, ROLE_MY_SAVE_FAIL, ROLE_MY_SAVE_REQUEST, ROLE_MY_SAVE_SUCCESS, ROLE_MY_SAVE_VALIDATION, ROLE_MY_UPDATE_FAIL, ROLE_MY_UPDATE_REQUEST, ROLE_MY_UPDATE_SUCCESS, ROLE_MY_UPDATE_VALIDATION, } from '../constants/menuConstants'
import { message } from 'antd'

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  Parent  ============================================

export const parentListReducer = (state = {parent:[], listsParent:[]}, action) => {
  switch (action.type) {
    case PARENT_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARENT_LIST_SUCCESS:
      return{ ...state, loading: false, parent: action.payload.data, listsParent: action.payload.data.data }
    case PARENT_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const parentListByAccessReducer = (state = {listsParentacc:[]}, action) => {
  switch (action.type) {
    case PARENT_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARENT_LIST_SUCCESS:
      return{ ...state, loading: false, listsParentacc: action.payload.data }
    case PARENT_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}



export const parentAllListReducer = (state = {listsParent:[]}, action) => {
  switch (action.type) {
    case PARENT_ALL_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARENT_ALL_LIST_SUCCESS:
      return{ ...state, loading: false, listsParent: action.payload.data }
    case PARENT_ALL_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const parentSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARENT_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case PARENT_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case PARENT_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case PARENT_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const parentEditReducer = (state = {parent:{}}, action) => {
  switch (action.type) {
    case PARENT_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARENT_EDIT_SUCCESS:
      return{ ...state, loading: false, parent: action.payload.data }
    case PARENT_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const parentUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARENT_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case PARENT_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case PARENT_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case PARENT_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const parentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PARENT_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case PARENT_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case PARENT_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Child  ============================================

export const childListReducer = (state = {child:[], listsChild:[]}, action) => {
  switch (action.type) {
    case CHILD_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case CHILD_LIST_SUCCESS:
      return{ ...state, loading: false, child: action.payload.data, listsChild: action.payload.data.data }
    case CHILD_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const childSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHILD_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case CHILD_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case CHILD_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case CHILD_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const childEditReducer = (state = {child:{}}, action) => {
  switch (action.type) {
    case CHILD_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case CHILD_EDIT_SUCCESS:
      return{ ...state, loading: false, child: action.payload.data }
    case CHILD_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const childUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHILD_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case CHILD_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case CHILD_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case CHILD_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const childDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHILD_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case CHILD_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case CHILD_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Role MY  ============================================

export const roleMYListReducer = (state = {role:[], listsRole:[]}, action) => {
  switch (action.type) {
    case ROLE_MY_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case ROLE_MY_LIST_SUCCESS:
      return{ ...state, loading: false, role: action.payload.data, listsRole: action.payload.data.data }
    case ROLE_MY_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_MY_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case ROLE_MY_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case ROLE_MY_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case ROLE_MY_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYEditReducer = (state = {role:{permissions_name:[]}}, action) => {
  switch (action.type) {
    case ROLE_MY_EDIT_REQUEST:
      return{ ...state, loading: true, error: []}
    case ROLE_MY_EDIT_SUCCESS:
      return{ ...state, loading: false, role: action.payload.data }
    case ROLE_MY_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_MY_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case ROLE_MY_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case ROLE_MY_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case ROLE_MY_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_MY_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case ROLE_MY_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case ROLE_MY_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Role MYCRM  ============================================

export const roleMYCRMListReducer = (state = {role:[], listsRole:[]}, action) => {
  switch (action.type) {
    case ROLE_MYCRM_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case ROLE_MYCRM_LIST_SUCCESS:
      return{ ...state, loading: false, role: action.payload.data, listsRole: action.payload.data.data }
    case ROLE_MYCRM_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYCRMSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_MYCRM_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case ROLE_MYCRM_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case ROLE_MYCRM_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case ROLE_MYCRM_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYCRMEditReducer = (state = {role:{permissions_name:[]}}, action) => {
  switch (action.type) {
    case ROLE_MYCRM_EDIT_REQUEST:
      return{ ...state, loading: true, error: []}
    case ROLE_MYCRM_EDIT_SUCCESS:
      return{ ...state, loading: false, role: action.payload.data }
    case ROLE_MYCRM_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYCRMUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_MYCRM_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case ROLE_MYCRM_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case ROLE_MYCRM_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case ROLE_MYCRM_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const roleMYCRMDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_MYCRM_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case ROLE_MYCRM_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case ROLE_MYCRM_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Menu  ============================================

export const menuListReducer = (state = {menu:[]}, action) => {
  switch (action.type) {
    case MENU_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case MENU_LIST_SUCCESS:
      return{ ...state, loading: false, menu: action.payload.data }
    case MENU_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Role  ============================================

export const roleAllListReducer = (state = {role:[]}, action) => {
  switch (action.type) {
    case ROLE_ALL_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case ROLE_ALL_LIST_SUCCESS:
      return{ ...state, loading: false, role: action.payload.data }
    case ROLE_ALL_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}