import { DEVELOPER_DELETE_REQUEST, DEVELOPER_DELETE_SUCCESS, DEVELOPER_EDIT_FAIL, DEVELOPER_EDIT_REQUEST, DEVELOPER_EDIT_SUCCESS, DEVELOPER_LIST_FAIL, DEVELOPER_LIST_REQUEST, DEVELOPER_LIST_SUCCESS, DEVELOPER_SAVE_FAIL, DEVELOPER_SAVE_REQUEST, DEVELOPER_SAVE_SUCCESS, DEVELOPER_SAVE_VALIDATION, DEVELOPER_UPDATE_FAIL, DEVELOPER_UPDATE_REQUEST, DEVELOPER_UPDATE_SUCCESS, DEVELOPER_UPDATE_VALIDATION,DEVELOPER_ALL_FAIL, DEVELOPER_ALL_REQUEST, DEVELOPER_ALL_SUCCESS, } from '../constants/developerConstants'
import axios from 'axios'

//=================================  Developer  ============================================

export const developerList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_LIST_REQUEST,
    })

    const { data } = await axios.get(`developer`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: DEVELOPER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_LIST_FAIL,
      payload: err          
    })
  }
}

export const developerAll = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_ALL_REQUEST,
    })

    const { data } = await axios.get(`get-developer-all`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: DEVELOPER_ALL_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_ALL_FAIL,
      payload: err          
    })
  }
}

export const developerSave = (name, email, phone, joindate, status, bank,id_office, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    for (let i = 0; i < bank.length; i++) {
      formData.append('bank[]', bank[i])
    }
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('join_date', joindate)
    formData.append('id_office', id_office)
    formData.append('status', status)
    formData.append('created_by', created_by)

    await axios.post('developer',formData,config)

    dispatch({
      type: DEVELOPER_SAVE_SUCCESS,
    })

    history.push('/developer-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: DEVELOPER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: DEVELOPER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const developerEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`developer/${id}/edit`)

    dispatch({
      type: DEVELOPER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const developerUpdate = (name, email, phone, joindate, status, bank,  updated_by, id, id_office, history) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    
    const formData = new FormData()
    for (let i = 0; i < bank.length; i++) {
      formData.append('bank[]', bank[i])
    }
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('join_date', joindate)
    formData.append('id_office', id_office)
    formData.append('status', status)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`developer/${id}`,formData,config)

    dispatch({
      type: DEVELOPER_UPDATE_SUCCESS,
    })
    history.push('/developer-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: DEVELOPER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: DEVELOPER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const developerDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_DELETE_REQUEST
    })

    await axios.delete(`developer/${id}`)

    dispatch({
      type: DEVELOPER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_DELETE_REQUEST,
      payload: err          
    })
  }
}