import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { adviserSave, officeAllList, adviserAllList } from '../../../../store/actions/memberActions'
import { Switch, Select } from 'antd';

import { provinceList, citybyprovinceList, officeareaList } from '../../../../store/actions/lookupActions'

const AdviserCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [office, setoffice] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [isnpwp, setisnpwp] = useState('')
  const [issalary, setissalary] = useState('')
  const [haired, sethaired] = useState('')
  const [nik, setNik] = useState('')

  const [isLoanSpesialis, setIsLoanSpesialis] = useState(false)
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [fax, setFax] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [officeArea, setOfficeArea] = useState('');
  const [password, setPassword] = useState('');


  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(officeAllList())
    dispatch(adviserAllList())
    dispatch(provinceList())
    dispatch(officeareaList())
  }, [dispatch])

  const dataprovinceList = useSelector(state => state.provinceList)
  const { listsProvince } = dataprovinceList

  const handleChangeProvince = (value) => {
    setProvince(value)
    dispatch(citybyprovinceList(value))
  }

  const datacitybyprovince = useSelector(state => state.citybyprovinceList)
  const { listsCity } = datacitybyprovince

  const dataofficeareaList = useSelector(state => state.officeareaList)
  const { listsOfficeArea } = dataofficeareaList

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const listsOffice = dataofficeAllList.listsOffice.map((item) => {return {value: item.value, label: item.label}})

  const dataadviserAllList = useSelector(state => state.adviserAllList)
  const listsAllAdviser = dataadviserAllList.listsAllAdviser.map((item) => {return {value: item.id, label: item.name}});

  const dataadviserSave = useSelector(state => state.adviserSave)
  const { validation } = dataadviserSave

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const created_by = userInfo.user.id
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(adviserSave(isLoanSpesialis, name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, created_by, history, nik, province, city, address, fax, whatsapp, officeArea, password))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Adviser</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
              <div className="form-group">
                <label for="name">Loan Spesialis</label><br/>
                <Switch onChange={() => setIsLoanSpesialis(!isLoanSpesialis)} />
              </div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <label for="nik">NIK</label>
                    <input type="text" 
                      className='form-control'
                      required 
                      onChange={(e) => setNik(e.target.value)}
                    />
                  </div>
                </div>

                {
                  !isLoanSpesialis &&
                  <div className="form-group d-flex flex-column">
                    <label for="name">Office</label>
                    <Select
                      showSearch
                      placeholder="Select an office"
                      optionFilterProp="children"
                      onChange={(value) => setoffice(value)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={listsOffice}
                      value={office}
                    />
                    {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['office']}</li>
                            </ul>
                          :
                            null
                        }
                  </div>

                }
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Gender</label>
                      <select 
                        className={ validation.length !== 0 && validation['gender'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setgender(e.target.value)}
                      >
                        <option disabled selected value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['gender']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-column">
                  <label for="name">Haired By</label>
                  <Select
                    showSearch
                    placeholder="Hired by"
                    optionFilterProp="children"
                    onChange={(value) => sethaired(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listsAllAdviser}
                    value={haired}
                  />
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['haired']}</li>
                          </ul>
                        :
                          null
                      }
                </div>

                {
                  isLoanSpesialis &&
                  <>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">Province</label>
                          <select 
                            className={ validation.length !== 0 && validation['province'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => handleChangeProvince(e.target.value)}
                          >
                            <option disabled selected value="">Select Province</option>
                            {
                              listsProvince.map((item, index) => {
                                return(
                                  <option value={item.id} key={index}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {/* {
                                validation.length !== 0 
                                ?
                                  <ul className="parsley-errors-list filled">
                                    <li className="parsley-required">{validation['province']}</li>
                                  </ul>
                                :
                                  null
                              } */}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">City</label>
                          <select 
                            className={ validation.length !== 0 && validation['city'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option disabled selected value="">Select City</option>
                            {
                              listsCity.map((item, index) => {
                                return(
                                  <option value={item.id} key={index}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {/* {
                                validation.length !== 0 
                                ?
                                  <ul className="parsley-errors-list filled">
                                    <li className="parsley-required">{validation['city']}</li>
                                  </ul>
                                :
                                  null
                              } */}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label for="name">Address</label>
                      <textarea
                        className={ validation.length !== 0 && validation['address'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['address']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">Fax</label>
                          <input type="text" 
                            className={ validation.length !== 0 && validation['fax'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => setFax(e.target.value)}
                          />
                          {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['fax']}</li>
                              </ul>
                            :
                              null
                          }
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">Whatsapp</label>
                          <input type="text" 
                            className={ validation.length !== 0 && validation['whatsapp'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => setWhatsapp(e.target.value)}
                          />
                          {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['whatsapp']}</li>
                              </ul>
                            :
                              null
                          }
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">Office Area</label>
                          <select 
                            className={ validation.length !== 0 && validation['office_area'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => setOfficeArea(e.target.value)}
                          >
                            <option disabled selected value="">Select Office Area</option>
                            {
                              listsOfficeArea.map((item, index) => {
                                return(
                                  <option value={item.id} key={index}>{item.name}</option>
                                )
                              })
                            }
                              </select>
                          {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['office_area']}</li>
                              </ul>
                            :
                              null
                          }
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="name">Password</label>
                          <input type="text" 
                            className={ validation.length !== 0 && validation['password'] ? 'form-control parsley-error' : 'form-control' }
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['password']}</li>
                              </ul>
                            :
                              null
                          }
                        </div>
                      </div>
                    </div>
                  </>
                }

                

                <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">NPWP</label><br/>
                      <Switch defaultChecked onChange={(e) => setisnpwp(e)} />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['npwp']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">Salary</label><br/>
                      <Switch defaultChecked onChange={(e) => setissalary(e)} />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['salary']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdviserCreate
