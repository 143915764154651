//=================================  News  ============================================

export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS'
export const NEWS_LIST_FAIL = ' NEWS_LIST_FAIL'

export const NEWS_SAVE_REQUEST = 'NEWS_SAVE_REQUEST'
export const NEWS_SAVE_SUCCESS = 'NEWS_SAVE_SUCCESS'
export const NEWS_SAVE_FAIL = ' NEWS_SAVE_FAIL'
export const NEWS_SAVE_VALIDATION = ' NEWS_SAVE_VALIDATION'

export const NEWS_EDIT_REQUEST = 'NEWS_EDIT_REQUEST'
export const NEWS_EDIT_SUCCESS = 'NEWS_EDIT_SUCCESS'
export const NEWS_EDIT_FAIL = ' NEWS_EDIT_FAIL'

export const NEWS_UPDATE_REQUEST = 'NEWS_UPDATE_REQUEST'
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS'
export const NEWS_UPDATE_FAIL = ' NEWS_UPDATE_FAIL'
export const NEWS_UPDATE_VALIDATION = ' NEWS_UPDATE_VALIDATION'

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST'
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS'
export const NEWS_DELETE_FAIL = ' NEWS_DELETE_FAIL'

//=================================  Partner  ============================================

export const PARTNER_LIST_REQUEST = 'PARTNER_LIST_REQUEST'
export const PARTNER_LIST_SUCCESS = 'PARTNER_LIST_SUCCESS'
export const PARTNER_LIST_FAIL = ' PARTNER_LIST_FAIL'

export const PARTNER_SAVE_REQUEST = 'PARTNER_SAVE_REQUEST'
export const PARTNER_SAVE_SUCCESS = 'PARTNER_SAVE_SUCCESS'
export const PARTNER_SAVE_FAIL = ' PARTNER_SAVE_FAIL'
export const PARTNER_SAVE_VALIDATION = ' PARTNER_SAVE_VALIDATION'

export const PARTNER_EDIT_REQUEST = 'PARTNER_EDIT_REQUEST'
export const PARTNER_EDIT_SUCCESS = 'PARTNER_EDIT_SUCCESS'
export const PARTNER_EDIT_FAIL = ' PARTNER_EDIT_FAIL'

export const PARTNER_UPDATE_REQUEST = 'PARTNER_UPDATE_REQUEST'
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS'
export const PARTNER_UPDATE_FAIL = ' PARTNER_UPDATE_FAIL'
export const PARTNER_UPDATE_VALIDATION = ' PARTNER_UPDATE_VALIDATION'

export const PARTNER_DELETE_REQUEST = 'PARTNER_DELETE_REQUEST'
export const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS'
export const PARTNER_DELETE_FAIL = ' PARTNER_DELETE_FAIL'

//=================================  Top Performer  ============================================

export const TOP_PERFORMER_LIST_REQUEST = 'TOP_PERFORMER_LIST_REQUEST'
export const TOP_PERFORMER_LIST_SUCCESS = 'TOP_PERFORMER_LIST_SUCCESS'
export const TOP_PERFORMER_LIST_FAIL = ' TOP_PERFORMER_LIST_FAIL'

export const TOP_PERFORMER_SAVE_REQUEST = 'TOP_PERFORMER_SAVE_REQUEST'
export const TOP_PERFORMER_SAVE_SUCCESS = 'TOP_PERFORMER_SAVE_SUCCESS'
export const TOP_PERFORMER_SAVE_FAIL = ' TOP_PERFORMER_SAVE_FAIL'
export const TOP_PERFORMER_SAVE_VALIDATION = ' TOP_PERFORMER_SAVE_VALIDATION'

export const TOP_PERFORMER_EDIT_REQUEST = 'TOP_PERFORMER_EDIT_REQUEST'
export const TOP_PERFORMER_EDIT_SUCCESS = 'TOP_PERFORMER_EDIT_SUCCESS'
export const TOP_PERFORMER_EDIT_FAIL = ' TOP_PERFORMER_EDIT_FAIL'

export const TOP_PERFORMER_UPDATE_REQUEST = 'TOP_PERFORMER_UPDATE_REQUEST'
export const TOP_PERFORMER_UPDATE_SUCCESS = 'TOP_PERFORMER_UPDATE_SUCCESS'
export const TOP_PERFORMER_UPDATE_FAIL = ' TOP_PERFORMER_UPDATE_FAIL'
export const TOP_PERFORMER_UPDATE_VALIDATION = ' TOP_PERFORMER_UPDATE_VALIDATION'

export const TOP_PERFORMER_DELETE_REQUEST = 'TOP_PERFORMER_DELETE_REQUEST'
export const TOP_PERFORMER_DELETE_SUCCESS = 'TOP_PERFORMER_DELETE_SUCCESS'
export const TOP_PERFORMER_DELETE_FAIL = ' TOP_PERFORMER_DELETE_FAIL'

//=================================  Career  ============================================

export const CAREER_LIST_REQUEST = 'CAREER_LIST_REQUEST'
export const CAREER_LIST_SUCCESS = 'CAREER_LIST_SUCCESS'
export const CAREER_LIST_FAIL = ' CAREER_LIST_FAIL'

export const CAREER_SAVE_REQUEST = 'CAREER_SAVE_REQUEST'
export const CAREER_SAVE_SUCCESS = 'CAREER_SAVE_SUCCESS'
export const CAREER_SAVE_FAIL = ' CAREER_SAVE_FAIL'
export const CAREER_SAVE_VALIDATION = ' CAREER_SAVE_VALIDATION'

export const CAREER_EDIT_REQUEST = 'CAREER_EDIT_REQUEST'
export const CAREER_EDIT_SUCCESS = 'CAREER_EDIT_SUCCESS'
export const CAREER_EDIT_FAIL = ' CAREER_EDIT_FAIL'

export const CAREER_UPDATE_REQUEST = 'CAREER_UPDATE_REQUEST'
export const CAREER_UPDATE_SUCCESS = 'CAREER_UPDATE_SUCCESS'
export const CAREER_UPDATE_FAIL = ' CAREER_UPDATE_FAIL'
export const CAREER_UPDATE_VALIDATION = ' CAREER_UPDATE_VALIDATION'

export const CAREER_DELETE_REQUEST = 'CAREER_DELETE_REQUEST'
export const CAREER_DELETE_SUCCESS = 'CAREER_DELETE_SUCCESS'
export const CAREER_DELETE_FAIL = ' CAREER_DELETE_FAIL'