import { CAREER_DELETE_FAIL, CAREER_DELETE_REQUEST, CAREER_DELETE_SUCCESS, CAREER_EDIT_FAIL, CAREER_EDIT_REQUEST, CAREER_EDIT_SUCCESS, CAREER_LIST_FAIL, CAREER_LIST_REQUEST, CAREER_LIST_SUCCESS, CAREER_SAVE_FAIL, CAREER_SAVE_REQUEST, CAREER_SAVE_SUCCESS, CAREER_SAVE_VALIDATION, CAREER_UPDATE_FAIL, CAREER_UPDATE_REQUEST, CAREER_UPDATE_SUCCESS, CAREER_UPDATE_VALIDATION, NEWS_DELETE_FAIL, NEWS_DELETE_REQUEST, NEWS_DELETE_SUCCESS, NEWS_EDIT_FAIL, NEWS_EDIT_REQUEST, NEWS_EDIT_SUCCESS, NEWS_LIST_FAIL, NEWS_LIST_REQUEST, NEWS_LIST_SUCCESS, NEWS_SAVE_FAIL, NEWS_SAVE_REQUEST, NEWS_SAVE_SUCCESS, NEWS_SAVE_VALIDATION, NEWS_UPDATE_FAIL, NEWS_UPDATE_REQUEST, NEWS_UPDATE_SUCCESS, NEWS_UPDATE_VALIDATION, PARTNER_DELETE_FAIL, PARTNER_DELETE_REQUEST, PARTNER_DELETE_SUCCESS, PARTNER_EDIT_FAIL, PARTNER_EDIT_REQUEST, PARTNER_EDIT_SUCCESS, PARTNER_LIST_FAIL, PARTNER_LIST_REQUEST, PARTNER_LIST_SUCCESS, PARTNER_SAVE_FAIL, PARTNER_SAVE_REQUEST, PARTNER_SAVE_SUCCESS, PARTNER_SAVE_VALIDATION, PARTNER_UPDATE_FAIL, PARTNER_UPDATE_REQUEST, PARTNER_UPDATE_SUCCESS, PARTNER_UPDATE_VALIDATION, TOP_PERFORMER_DELETE_FAIL, TOP_PERFORMER_DELETE_REQUEST, TOP_PERFORMER_DELETE_SUCCESS, TOP_PERFORMER_EDIT_FAIL, TOP_PERFORMER_EDIT_REQUEST, TOP_PERFORMER_EDIT_SUCCESS, TOP_PERFORMER_LIST_FAIL, TOP_PERFORMER_LIST_REQUEST, TOP_PERFORMER_LIST_SUCCESS, TOP_PERFORMER_SAVE_FAIL, TOP_PERFORMER_SAVE_REQUEST, TOP_PERFORMER_SAVE_SUCCESS, TOP_PERFORMER_SAVE_VALIDATION, TOP_PERFORMER_UPDATE_FAIL, TOP_PERFORMER_UPDATE_REQUEST, TOP_PERFORMER_UPDATE_SUCCESS, TOP_PERFORMER_UPDATE_VALIDATION } from '../constants/websiteConstants'
import axios from 'axios'

//=================================  News  ============================================

export const newsList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LIST_REQUEST,
    })

    const { data } = await axios.get(`news`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: NEWS_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: NEWS_LIST_FAIL,
      payload: err          
    })
  }
}

export const newsSave = (type, title, intro, content, contentplain, publishdate, status, file, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('type', type)
    formData.append('title', title)
    formData.append('intro', intro)
    formData.append('content', content)
    formData.append('content_plain', contentplain)
    formData.append('publish_date', publishdate)
    formData.append('status', status)
    formData.append('image', file)
    formData.append('created_by', created_by)

    await axios.post('news',formData,config)

    dispatch({
      type: NEWS_SAVE_SUCCESS,
    })

    history.push('/news-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: NEWS_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: NEWS_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const newsEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_EDIT_REQUEST,
    })

    const { data } = await axios.get(`news/${id}/edit`)

    dispatch({
      type: NEWS_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: NEWS_EDIT_FAIL,
      payload: err          
    })
  }
}

export const newsUpdate = (type, title, intro, content, contentplain, publishdate, status, file, updated_by, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('type', type)
    formData.append('title', title)
    formData.append('intro', intro)
    formData.append('content', content)
    formData.append('content_plain', contentplain)
    formData.append('publish_date', publishdate)
    formData.append('status', status)
    formData.append('image', file)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`news/${id}`,formData,config)

    dispatch({
      type: NEWS_UPDATE_SUCCESS,
    })
    history.push('/news-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: NEWS_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: NEWS_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const newsDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_DELETE_REQUEST
    })

    await axios.delete(`news/${id}`)

    dispatch({
      type: NEWS_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: NEWS_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Partner  ============================================

export const partnerList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: PARTNER_LIST_REQUEST,
    })

    const { data } = await axios.get(`partner`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: PARTNER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARTNER_LIST_FAIL,
      payload: err          
    })
  }
}

export const partnerSave = (name, file, status, sort, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: PARTNER_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('status', status)
    formData.append('sort', sort)
    formData.append('image', file)
    formData.append('created_by', created_by)

    await axios.post('partner',formData,config)

    dispatch({
      type: PARTNER_SAVE_SUCCESS,
    })

    history.push('/partner-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PARTNER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PARTNER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const partnerEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PARTNER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`partner/${id}/edit`)

    dispatch({
      type: PARTNER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARTNER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const partnerUpdate = (name, status, file, sort, id, updated_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: PARTNER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('status', status)
    formData.append('image', file)
    formData.append('sort', sort)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`partner/${id}`,formData,config)

    dispatch({
      type: PARTNER_UPDATE_SUCCESS,
    })
    history.push('/partner-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PARTNER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const partnerDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PARTNER_DELETE_REQUEST
    })

    await axios.delete(`partner/${id}`)

    dispatch({
      type: PARTNER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARTNER_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Top Performer  ============================================

export const topperformerList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PERFORMER_LIST_REQUEST,
    })

    const { data } = await axios.get(`top-performer`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: TOP_PERFORMER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: TOP_PERFORMER_LIST_FAIL,
      payload: err          
    })
  }
}

export const topperformerSave = (month, publish, file1, file2, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PERFORMER_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('month', month)
    formData.append('publish', publish)
    formData.append('file1', file1)
    formData.append('file2', file2)
    formData.append('created_by', created_by)

    await axios.post('top-performer',formData,config)

    dispatch({
      type: TOP_PERFORMER_SAVE_SUCCESS,
    })

    history.push('/top-performer-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: TOP_PERFORMER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: TOP_PERFORMER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const topperformerEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PERFORMER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`top-performer/${id}/edit`)

    dispatch({
      type: TOP_PERFORMER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: TOP_PERFORMER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const topperformerUpdate = (month, publish, file1, file2, updated_by, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PERFORMER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('month', month)
    formData.append('publish', publish)
    formData.append('file1', file1)
    formData.append('file2', file2)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`top-performer/${id}`,formData,config)

    dispatch({
      type: TOP_PERFORMER_UPDATE_SUCCESS,
    })
    history.push('/top-performer-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: TOP_PERFORMER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: TOP_PERFORMER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const topperformerDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PERFORMER_DELETE_REQUEST
    })

    await axios.delete(`top-performer/${id}`)

    dispatch({
      type: TOP_PERFORMER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: TOP_PERFORMER_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Career  ============================================

export const careerList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_LIST_REQUEST,
    })

    const { data } = await axios.get(`career`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: CAREER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CAREER_LIST_FAIL,
      payload: err          
    })
  }
}

export const careerSave = (link, status, type, title, location, content, contentplain, file, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('link', link)
    formData.append('status', status)
    formData.append('type', type)
    formData.append('title', title)
    formData.append('location', location)
    formData.append('content', content)
    formData.append('content_plain', contentplain)
    formData.append('image', file)
    formData.append('created_by', created_by)

    await axios.post('career',formData,config)

    dispatch({
      type: CAREER_SAVE_SUCCESS,
    })

    history.push('/career-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CAREER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CAREER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const careerEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`career/${id}/edit`)

    dispatch({
      type: CAREER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CAREER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const careerUpdate = (link, status, type, title, location, content, contentplain, file, updated_by, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('link', link)
    formData.append('status', status)
    formData.append('type', type)
    formData.append('title', title)
    formData.append('location', location)
    formData.append('content', content)
    formData.append('content_plain', contentplain)
    formData.append('image', file)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`career/${id}`,formData,config)

    dispatch({
      type: CAREER_UPDATE_SUCCESS,
    })
    history.push('/career-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CAREER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CAREER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const careerDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_DELETE_REQUEST
    })

    await axios.delete(`career/${id}`)

    dispatch({
      type: CAREER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CAREER_DELETE_FAIL,
      payload: err          
    })
  }
}
