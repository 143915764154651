import { CITY_BY_PARENT_LIST_FAIL, CITY_BY_PARENT_LIST_REQUEST, CITY_BY_PARENT_LIST_SUCCESS, PROVINCE_LIST_FAIL, PROVINCE_LIST_REQUEST, PROVINCE_LIST_SUCCESS, OFFICE_AREA_LIST_REQUEST, OFFICE_AREA_LIST_SUCCESS, OFFICE_AREA_LIST_FAIL } from '../constants/lookupConstants';

//=================================  Province  ============================================

export const provinceListReducer = (state = {listsProvince:[]}, action) => {
  switch (action.type) {
    case PROVINCE_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PROVINCE_LIST_SUCCESS:
      return{ ...state, loading: false, listsProvince: action.payload.data }
    case PROVINCE_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  City  ============================================

export const citybyprovinceListReducer = (state = {listsCity:[]}, action) => {
  switch (action.type) {
    case CITY_BY_PARENT_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case CITY_BY_PARENT_LIST_SUCCESS:
      return{ ...state, loading: false, listsCity: action.payload.data }
    case CITY_BY_PARENT_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Office Area  ============================================

export const officeareaListReducer = (state = {listsOfficeArea:[]}, action) => {
  switch (action.type) {
    case OFFICE_AREA_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case OFFICE_AREA_LIST_SUCCESS:
      return{ ...state, loading: false, listsOfficeArea: action.payload.data }
    case OFFICE_AREA_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}