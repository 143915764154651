export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST'
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS'
export const BANK_LIST_FAIL = 'BANK_LIST_FAIL'

//=================================  MITRA  ============================================

export const MITRA_LIST_REQUEST = 'MITRA_LIST_REQUEST'
export const MITRA_LIST_SUCCESS = 'MITRA_LIST_SUCCESS'
export const MITRA_LIST_FAIL = 'MITRA_LIST_FAIL'

export const MITRA_SAVE_REQUEST = 'MITRA_SAVE_REQUEST'
export const MITRA_SAVE_SUCCESS = 'MITRA_SAVE_SUCCESS'
export const MITRA_SAVE_FAIL = 'MITRA_SAVE_FAIL'
export const MITRA_SAVE_VALIDATION = 'MITRA_SAVE_VALIDATION'

export const MITRA_EDIT_REQUEST = 'MITRA_EDIT_REQUEST'
export const MITRA_EDIT_SUCCESS = 'MITRA_EDIT_SUCCESS'
export const MITRA_EDIT_FAIL = 'MITRA_EDIT_FAIL'

export const MITRA_UPDATE_REQUEST = 'MITRA_UPDATE_REQUEST'
export const MITRA_UPDATE_SUCCESS = 'MITRA_UPDATE_SUCCESS'
export const MITRA_UPDATE_FAIL = 'MITRA_UPDATE_FAIL'
export const MITRA_UPDATE_VALIDATION = 'MITRA_UPDATE_VALIDATION'

export const MITRA_DELETE_REQUEST = 'MITRA_DELETE_REQUEST'
export const MITRA_DELETE_SUCCESS = 'MITRA_DELETE_SUCCESS'
export const MITRA_DELETE_FAIL = 'MITRA_DELETE_FAIL'