//=================================  Province  ============================================

export const PROVINCE_LIST_REQUEST = 'PROVINCE_LIST_REQUEST'
export const PROVINCE_LIST_SUCCESS = 'PROVINCE_LIST_SUCCESS'
export const PROVINCE_LIST_FAIL = ' PROVINCE_LIST_FAIL'

//=================================  City  ============================================

export const CITY_BY_PARENT_LIST_REQUEST = 'CITY_BY_PARENT_LIST_REQUEST'
export const CITY_BY_PARENT_LIST_SUCCESS = 'CITY_BY_PARENT_LIST_SUCCESS'
export const CITY_BY_PARENT_LIST_FAIL = ' CITY_BY_PARENT_LIST_FAIL'

//=================================  Office Area  ============================================

export const OFFICE_AREA_LIST_REQUEST = 'OFFICE_AREA_LIST_REQUEST'
export const OFFICE_AREA_LIST_SUCCESS = 'OFFICE_AREA_LIST_SUCCESS'
export const OFFICE_AREA_LIST_FAIL = ' OFFICE_AREA_LIST_FAIL'