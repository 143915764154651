import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { careerList,  careerDelete } from '../../../store/actions/websiteActions'
import { Popconfirm, message, Empty } from 'antd'

const CareerIndex = () => {
  const [search, setsearch] = useState('')
  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(careerList(1, search))
    localStorage.removeItem('id_career')
  }, [dispatch])

  const datacareerList = useSelector(state => state.careerList)
  const{ career, listsCareer } = datacareerList

  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin

  const handleEdit = (id) => {
    localStorage.setItem('id_career', id)
    history.push('/career-edit')
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    dispatch(careerList(pageNumber, search));
  }

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(careerList(1, search));
  }

  const listsPaginate = (jumlah_page, current_page) => {
    let item = [];
    for (let index = 1; index <= jumlah_page; index++) {
      item.push(index);
    }
    console.log(item);
    return item.map((page) => {
      if (current_page === 1) {
        if (page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else if (current_page === jumlah_page) {
        if (page === current_page || page === parseInt(current_page) - 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else {
        if (page === parseInt(current_page) - 1 || page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      }
    })
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Career</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {
                    userInfo.permission.find(e => e === 'career-create')
                    ?
                      <Link to={'/career-create'} className="btn btn-info btn-sm">Create</Link>
                    :
                      null
                  }
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input type="text" className="form-control" placeholder="Search By Name" style={{ float: 'right', width:'50%' }} onChange={(e) => setsearch(e.target.value)} />
                  <button className="fa fa-search form-control-feedback right btn btn-link" style={{ height:'100%', margin:'auto', textDecoration:'none' }} aria-hidden="true" onClick={(e) => handleSearch(e)}></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Type</th>
                    <th>Job Position</th>
                    <th>Registration Link</th>
                    <th>Status</th>
                    <th style={{ textAlign:'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (
                      listsCareer.length < '1' ?
                        <tr>
                          <th colSpan="6"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></th>
                        </tr>
                      :
                        listsCareer.map((item, index) => {
                          return(
                            <tr key={item.id}>
                              <th scope="row">{ parseInt(index) + parseInt(1) }</th>
                              <td>{item.type}</td>
                              <td>{item.title}</td>
                              <td>{item.link}</td>
                              <td>{( item.active ? 'active' : 'deactive' )}</td>
                              <td align="center">
                                {
                                  userInfo.permission.find(e => e === 'career-edit')
                                  ?
                                    <button onClick={() => handleEdit(item.id)} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i> Edit </button>
                                  :
                                    null
                                }
                                {
                                  userInfo.permission.find(e => e === 'career-delete')
                                  ?
                                    <Popconfirm
                                      title="Are you sure to delete this parent?"
                                      onConfirm={() => dispatch(careerDelete(item.id)).then(() => dispatch(careerList(1, search)) ) }
                                      onCancel={() => message.info('Canceled!')}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <button className="btn btn-danger btn-sm"><i className="fa fa-trash-o"></i> Delete </button>
                                    </Popconfirm>
                                  :
                                    null
                                }
                              </td>
                            </tr>
                          )
                        })
                    )
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li className={( career.prev_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(career.current_page) - 1)}  tabindex="-1">Previous</a>
                  </li>
                  {
                    listsPaginate(career.last_page, career.current_page)
                  }
                  <li className={( career.next_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(career.current_page) + 1)} >Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerIndex
