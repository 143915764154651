export const OFFICEALL_LIST_REQUEST = 'OFFICEALL_LIST_REQUEST'
export const OFFICEALL_LIST_SUCCESS = 'OFFICEALL_LIST_SUCCESS'
export const OFFICEALL_LIST_FAIL = 'OFFICEALL_LIST_FAIL'

export const ADVISERALL_LIST_REQUEST = 'ADVISERALL_LIST_REQUEST'
export const ADVISERALL_LIST_SUCCESS = 'ADVISERALL_LIST_SUCCESS'
export const ADVISERALL_LIST_FAIL = 'ADVISERALL_LIST_FAIL'

//=================================  Adviser  ============================================

export const ADVISER_LIST_REQUEST = 'ADVISER_LIST_REQUEST'
export const ADVISER_LIST_SUCCESS = 'ADVISER_LIST_SUCCESS'
export const ADVISER_LIST_FAIL = 'ADVISER_LIST_FAIL'

export const ADVISER_SAVE_REQUEST = 'ADVISER_SAVE_REQUEST'
export const ADVISER_SAVE_SUCCESS = 'ADVISER_SAVE_SUCCESS'
export const ADVISER_SAVE_FAIL = 'ADVISER_SAVE_FAIL'
export const ADVISER_SAVE_VALIDATION = 'ADVISER_SAVE_VALIDATION'

export const ADVISER_EDIT_REQUEST = 'ADVISER_EDIT_REQUEST'
export const ADVISER_EDIT_SUCCESS = 'ADVISER_EDIT_SUCCESS'
export const ADVISER_EDIT_FAIL = 'ADVISER_EDIT_FAIL'

export const ADVISER_UPDATE_REQUEST = 'ADVISER_UPDATE_REQUEST'
export const ADVISER_UPDATE_SUCCESS = 'ADVISER_UPDATE_SUCCESS'
export const ADVISER_UPDATE_FAIL = 'ADVISER_UPDATE_FAIL'
export const ADVISER_UPDATE_VALIDATION = 'ADVISER_UPDATE_VALIDATION'

export const ADVISER_DELETE_REQUEST = 'ADVISER_DELETE_REQUEST'
export const ADVISER_DELETE_SUCCESS = 'ADVISER_DELETE_SUCCESS'
export const ADVISER_DELETE_FAIL = 'ADVISER_DELETE_FAIL'

//=================================  Principal  ============================================

export const PRINCIPAL_LIST_REQUEST = 'PRINCIPAL_LIST_REQUEST'
export const PRINCIPAL_LIST_SUCCESS = 'PRINCIPAL_LIST_SUCCESS'
export const PRINCIPAL_LIST_FAIL = 'PRINCIPAL_LIST_FAIL'

export const PRINCIPAL_BY_OFFICE_LIST_REQUEST = 'PRINCIPAL_BY_OFFICE_LIST_REQUEST'
export const PRINCIPAL_BY_OFFICE_LIST_SUCCESS = 'PRINCIPAL_BY_OFFICE_LIST_SUCCESS'
export const PRINCIPAL_BY_OFFICE_LIST_FAIL = 'PRINCIPAL_BY_OFFICE_LIST_FAIL'

export const PRINCIPAL_SAVE_REQUEST = 'PRINCIPAL_SAVE_REQUEST'
export const PRINCIPAL_SAVE_SUCCESS = 'PRINCIPAL_SAVE_SUCCESS'
export const PRINCIPAL_SAVE_FAIL = 'PRINCIPAL_SAVE_FAIL'
export const PRINCIPAL_SAVE_VALIDATION = 'PRINCIPAL_SAVE_VALIDATION'

export const PRINCIPAL_EDIT_REQUEST = 'PRINCIPAL_EDIT_REQUEST'
export const PRINCIPAL_EDIT_SUCCESS = 'PRINCIPAL_EDIT_SUCCESS'
export const PRINCIPAL_EDIT_FAIL = 'PRINCIPAL_EDIT_FAIL'

export const PRINCIPAL_UPDATE_REQUEST = 'PRINCIPAL_UPDATE_REQUEST'
export const PRINCIPAL_UPDATE_SUCCESS = 'PRINCIPAL_UPDATE_SUCCESS'
export const PRINCIPAL_UPDATE_FAIL = 'PRINCIPAL_UPDATE_FAIL'
export const PRINCIPAL_UPDATE_VALIDATION = 'PRINCIPAL_UPDATE_VALIDATION'

export const PRINCIPAL_DELETE_REQUEST = 'PRINCIPAL_DELETE_REQUEST'
export const PRINCIPAL_DELETE_SUCCESS = 'PRINCIPAL_DELETE_SUCCESS'
export const PRINCIPAL_DELETE_FAIL = 'PRINCIPAL_DELETE_FAIL'

//=================================  Admin  ============================================

export const ADMIN_LIST_REQUEST = 'ADMIN_LIST_REQUEST'
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS'
export const ADMIN_LIST_FAIL = 'ADMIN_LIST_FAIL'

export const ADMIN_SAVE_REQUEST = 'ADMIN_SAVE_REQUEST'
export const ADMIN_SAVE_SUCCESS = 'ADMIN_SAVE_SUCCESS'
export const ADMIN_SAVE_FAIL = 'ADMIN_SAVE_FAIL'
export const ADMIN_SAVE_VALIDATION = 'ADMIN_SAVE_VALIDATION'

export const ADMIN_EDIT_REQUEST = 'ADMIN_EDIT_REQUEST'
export const ADMIN_EDIT_SUCCESS = 'ADMIN_EDIT_SUCCESS'
export const ADMIN_EDIT_FAIL = 'ADMIN_EDIT_FAIL'

export const ADMIN_UPDATE_REQUEST = 'ADMIN_UPDATE_REQUEST'
export const ADMIN_UPDATE_SUCCESS = 'ADMIN_UPDATE_SUCCESS'
export const ADMIN_UPDATE_FAIL = 'ADMIN_UPDATE_FAIL'
export const ADMIN_UPDATE_VALIDATION = 'ADMIN_UPDATE_VALIDATION'

export const ADMIN_DELETE_REQUEST = 'ADMIN_DELETE_REQUEST'
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS'
export const ADMIN_DELETE_FAIL = 'ADMIN_DELETE_FAIL'


//=================================  CSM  ============================================

export const CSM_LIST_REQUEST = 'CSM_LIST_REQUEST'
export const CSM_LIST_SUCCESS = 'CSM_LIST_SUCCESS'
export const CSM_LIST_FAIL = 'CSM_LIST_FAIL'

export const CSM_SAVE_REQUEST = 'CSM_SAVE_REQUEST'
export const CSM_SAVE_SUCCESS = 'CSM_SAVE_SUCCESS'
export const CSM_SAVE_FAIL = 'CSM_SAVE_FAIL'
export const CSM_SAVE_VALIDATION = 'CSM_SAVE_VALIDATION'

export const CSM_EDIT_REQUEST = 'CSM_EDIT_REQUEST'
export const CSM_EDIT_SUCCESS = 'CSM_EDIT_SUCCESS'
export const CSM_EDIT_FAIL = 'CSM_EDIT_FAIL'

export const CSM_UPDATE_REQUEST = 'CSM_UPDATE_REQUEST'
export const CSM_UPDATE_SUCCESS = 'CSM_UPDATE_SUCCESS'
export const CSM_UPDATE_FAIL = 'CSM_UPDATE_FAIL'
export const CSM_UPDATE_VALIDATION = 'CSM_UPDATE_VALIDATION'

export const CSM_DELETE_REQUEST = 'CSM_DELETE_REQUEST'
export const CSM_DELETE_SUCCESS = 'CSM_DELETE_SUCCESS'
export const CSM_DELETE_FAIL = 'CSM_DELETE_FAIL'

//=================================  Admin Branch  ============================================

export const ADMIN_BRANCH_LIST_REQUEST = 'ADMIN_BRANCH_LIST_REQUEST'
export const ADMIN_BRANCH_LIST_SUCCESS = 'ADMIN_BRANCH_LIST_SUCCESS'
export const ADMIN_BRANCH_LIST_FAIL = 'ADMIN_BRANCH_LIST_FAIL'

export const ADMIN_BRANCH_SAVE_REQUEST = 'ADMIN_BRANCH_SAVE_REQUEST'
export const ADMIN_BRANCH_SAVE_SUCCESS = 'ADMIN_BRANCH_SAVE_SUCCESS'
export const ADMIN_BRANCH_SAVE_FAIL = 'ADMIN_BRANCH_SAVE_FAIL'
export const ADMIN_BRANCH_SAVE_VALIDATION = 'ADMIN_BRANCH_SAVE_VALIDATION'

export const ADMIN_BRANCH_EDIT_REQUEST = 'ADMIN_BRANCH_EDIT_REQUEST'
export const ADMIN_BRANCH_EDIT_SUCCESS = 'ADMIN_BRANCH_EDIT_SUCCESS'
export const ADMIN_BRANCH_EDIT_FAIL = 'ADMIN_BRANCH_EDIT_FAIL'

export const ADMIN_BRANCH_UPDATE_REQUEST = 'ADMIN_BRANCH_UPDATE_REQUEST'
export const ADMIN_BRANCH_UPDATE_SUCCESS = 'ADMIN_BRANCH_UPDATE_SUCCESS'
export const ADMIN_BRANCH_UPDATE_FAIL = 'ADMIN_BRANCH_UPDATE_FAIL'
export const ADMIN_BRANCH_UPDATE_VALIDATION = 'ADMIN_BRANCH_UPDATE_VALIDATION'

export const ADMIN_BRANCH_DELETE_REQUEST = 'ADMIN_BRANCH_DELETE_REQUEST'
export const ADMIN_BRANCH_DELETE_SUCCESS = 'ADMIN_BRANCH_DELETE_SUCCESS'
export const ADMIN_BRANCH_DELETE_FAIL = 'ADMIN_BRANCH_DELETE_FAIL'
