import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { developerAll } from '../../../store/actions/developerActions'
import { developeremployeeSave } from '../../../store/actions/developeremployeeActions'
import { Row, Select } from 'antd';

const DeveloperEmployeeCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [no_telepon, setno_telepon] = useState('')
  const [password, setpassword] = useState('')
  const [retype_password, setretype_password] = useState('')
  const [address, setaddress] = useState('')
  const [developer_id, setdeveloper_id] = useState('')
  const [search, setsearch] = useState('')
  let dispatch = useDispatch()
  let dispatchOf = useDispatch()
  let history = useHistory()

  const { Option } = Select;

  useEffect(() => {
    dispatchOf(developerAll(1, search))
  }, [dispatchOf])
  
  const datadeveloperAll = useSelector(state => state.developerAll)
  const{ developer, AllDeveloper } = datadeveloperAll

  const datadeveloperemployeeSave = useSelector(state => state.developeremployeeSave)
  const { validation } = datadeveloperemployeeSave


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(developeremployeeSave(name, email, no_telepon, address, password, retype_password,developer_id, history))
  }



  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Developer Employee</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-9 col-sm-9 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">No Telepon</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['no_telepon'] ? 'form-control parsley-error' : 'form-control' }
                         
                        onChange={(e) => setno_telepon(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                         
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="password">Password</label>
                      <input type="password" 
                        className={ validation.length !== 0 && validation['password'] ? 'form-control parsley-error' : 'form-control' }
                         
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['password']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="retype_password">Confirm Password</label>
                      <input type="password" 
                        className={ validation.length !== 0 && validation['retype_password'] ? 'form-control parsley-error' : 'form-control' }
                         
                        onChange={(e) => setretype_password(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['retype_password']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                
                <div className="row">
                <div className="col-12">
                    <div className="form-group">
                      <label for="address">Address</label>
                      <textarea name="address" className={ validation.length !== 0 && validation['address'] ? 'form-control parsley-error' : 'form-control' } id="address" onChange={(e) => setaddress(e.target.value)} rows="5" ></textarea>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['address']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className='form-group mb-4'>
                  <label for="developer_office">Select Developer Office</label>
                  <Select defaultValue="Developer Office" onChange={(e) => setdeveloper_id(e)} style={{ width: '100%' }} >
                    {(
                      developer.length < '1' ? ''
                      :
                      developer.map((item, index) => {
                        return(
                          <Option value={item.id} key={index}>{item.name}</Option>
                        )
                      })
                    )}
                    
                  </Select>
                </div>

                
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeveloperEmployeeCreate
