import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm, message, Empty } from 'antd';
import { asyncGetAllPromoCategories, asyncGetAllPromo, asyncGetAssetPromoCategory, asyncDeletePromo } from '../../../store/actions/promoActions';
import PromoAdd from './PromoAdd';
import PromoEdit from './PromoEdit';

function Promo() {
  const [pageNumber, setPageNumber] = useState(1);
  const [editedPromoData, setEditedPromoData] = useState(null);
  const [showModalAddPromo, setShowModalPromo] = useState(false);
  const [showModalEditPromo, setShowEditModalPromo] = useState(false);
  
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncGetAllPromo(pageNumber));
    dispatch(asyncGetAssetPromoCategory());
  }, [dispatch])

  const dataPromo = useSelector(state => state.promo);
  const dataPromoCategoryAsset = useSelector(state => state.assetPromoCategory);

  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin

  const handleShowModalEditPromo = (item) => {
    setEditedPromoData(item);
    setShowEditModalPromo(true);
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setPageNumber(pageNumber);
    dispatch(asyncGetAllPromoCategories(pageNumber));
  }

  const listsPaginate = (jumlah_page, current_page) => {
    let item = [];
    for (let index = 1; index <= jumlah_page; index++) {
      item.push(index);
    }
    return item.map((page) => {
      if (current_page === 1) {
        if (page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else if (current_page === jumlah_page) {
        if (page === current_page || page === parseInt(current_page) - 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else {
        if (page === parseInt(current_page) - 1 || page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      }
    })
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Promo</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {
                    userInfo.user.roles[0].name === 'Super Admin'
                    ?
                      <button onClick={() => setShowModalPromo(true)} className="btn btn-info btn-sm">Create New Promo</button>
                    :
                      null
                  }
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Id Promo</th>
                    <th>Promo Category</th>
                    <th>Promo Title</th>
                    <th>Publish Time</th>
                    <th style={{ textAlign:'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (
                      dataPromo.length < '1' ?
                        <tr>
                          <th colSpan="6"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></th>
                        </tr>
                      :
                        dataPromo.data.map((item) => {
                          return(
                            <tr key={item.id}>
                              <th scope="row">{ item.id }</th>
                              <td>{dataPromoCategoryAsset[item.category_id]}</td>
                              <td>{item.title}</td>
                              <td>{new Date(item.created_at).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })}</td>
                              <td align="center">
                                {
                                  userInfo.user.roles[0].name === 'Super Admin'
                                  ?
                                    <button onClick={() => handleShowModalEditPromo(item)} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i> Edit </button>
                                  :
                                    null
                                }
                                {
                                  userInfo.user.roles[0].name === 'Super Admin'
                                  ?
                                    <Popconfirm
                                      title="Are you sure to delete this promo?"
                                      onConfirm={() => dispatch(asyncDeletePromo(item.id)) }
                                      onCancel={() => message.info('Canceled!')}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <button className="btn btn-danger btn-sm"><i className="fa fa-trash-o"></i> Delete </button>
                                    </Popconfirm>
                                  :
                                    null
                                }
                              </td>
                            </tr>
                          )
                        })
                    )
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li className={( dataPromo.prev_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(dataPromo.current_page) - 1)}  tabindex="-1">Previous</a>
                  </li>
                  {
                    listsPaginate(dataPromo.last_page, dataPromo.current_page)
                  }
                  <li className={( dataPromo.next_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(dataPromo.current_page) + 1)} >Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Modal add promo */}
      <PromoAdd showModalAddPromo={showModalAddPromo} setShowModalPromo={setShowModalPromo} dataPromoCategoryAsset={dataPromoCategoryAsset} dispatch={dispatch} />
      {/* Modal edit promo */}
      { editedPromoData &&
        <PromoEdit editedPromoData={editedPromoData} setEditedPromoData={setEditedPromoData} showModalEditPromo={showModalEditPromo} setShowEditModalPromo={setShowEditModalPromo} dataPromoCategoryAsset={dataPromoCategoryAsset} dispatch={dispatch} />
      }
    </div>
  )
}

export default Promo