import React from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Sidebar = () => {

  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin

  let menu = userInfo.menu

// console.log(`Menu = ${menu}`);
// console.log(`Menus = ${menus}`);
// console.log(userInfo.permission.find(e => e === 'setting-read'));
// console.log(`CheckPermis = ${userInfo.find(e => e.permission === `read setting`)}`);

  return (
    <div className="col-md-3 left_col menu_fixed">
      <div className="left_col scroll-view">
        <div className="navbar nav_title" style={{ border: 0 }}>
          <Link to="/" className="site_title">
            <img src="images/lm-logo-2019.jpg" alt="..." className="profile_img" style={{ width: '100%' }} />
          </Link>
        </div>

        <div className="clearfix"></div>

        {/* <div className="profile clearfix">
          <div className="profile_pic">
            <img src="images/img.jpg" alt="..." className="img-circle profile_img"/>
          </div>
          <div className="profile_info">
            <span>Welcome,</span>
            <h2>{userInfo.user.name}</h2>
          </div>
        </div> */}

        <br />

        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
          <div className="menu_section">
            {/* <h3>General</h3> */}
            <ul className="nav side-menu">
              <SingleMenu to="/" label="Dashboard" activeOnlyWhenExact={true} icon='fa fa-laptop' />
              {/* <MultipleMenu data={menus} /> */}
              {
                menu.map((key) => {
                  if (key.child.length >= 1) {
                    if (userInfo.permission.find(e => e === `${key.path}-read`)) {
                      return(
                        <MultipleMenu data={key} key={key.id} />
                      )
                    }
                  } else {
                    if (userInfo.permission.find(e => e === `${key.path}-read`)) {
                      return(
                        <SingleMenu to={`/${key.path}-index`} label={key.name} icon={key.icon} key={key.id} />
                      )
                    }
                  }

                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const MultipleMenu = ({ data }) => {
  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin
  let location = useLocation()
  const pathnow = location.pathname.split(/[/"-]/g);
  let find;
  let realPath;
  if (pathnow.length === 3) {
    find = data.child.find(x => x.path === pathnow[1])
    realPath = pathnow[1]
  }else if(pathnow.length === 4) {
    find = data.child.find(x => x.path === `${pathnow[1]}-${pathnow[2]}`)
    realPath = `${pathnow[1]}-${pathnow[2]}`
  }
  return (
    <li className={find ? "active" : "" }><a><i className={data.icon}></i> {data.name} <span className="fa fa-chevron-down"></span></a>
      <ul className={find ? "nav child_menu d-block" : "nav child_menu" }>
        {
          data.child.map((key) => {
            if (userInfo.permission.find(e => e === `${key.path}-read`)) {
              return(
                <li className={realPath === key.path ? "current-page" : "" } key={key.id}>
                  <Link to={`/${key.path}-index`}>{`${key.name}`}</Link>
                </li>
              )
            }
          })
        }
      </ul>
    </li>
  )
}

const SingleMenu = ({ label, to, activeOnlyWhenExact, icon  }) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
    <li className={match ? "current-page" : ""}>
      <Link to={to}><i className={icon}></i>{label}</Link>
    </li>
  )
}

export default Sidebar
