import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import store from './store/index'
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'
import 'antd/dist/antd.css';

axios.interceptors.response.use(null, function(error) {
  if (error.response.status === 401) {
    console.log(error.response.status);
    localStorage.removeItem('userInfo')
    axios.defaults.headers.common.Authorization = null;
    window.location.href = process.env.LINK_REDIRECT
  }
  return Promise.reject(error);
});
// Prod
// axios.defaults.baseURL = 'https://www.loanmarket.co.id/api_v2/public/api/my/';
// Dev
// axios.defaults.baseURL = 'http://api_v2.test/api/my/';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const userInfo = JSON.parse(localStorage.getItem('userInfo'))

if (userInfo) {
  axios.defaults.headers.common.Authorization = `Bearer ${userInfo.token}`;
}else{
  axios.defaults.headers.common.Authorization = null;
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
