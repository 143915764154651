import React, { useState , useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { csmSave ,officeAllList} from '../../../../store/actions/memberActions'
import { Select } from 'antd';

const CSMCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [id_office, setoffice] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [joindate, setjoindate] = useState('')
  const [password, setpassword] = useState('')
  const [retype_password, setretype_password] = useState('')

  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(officeAllList())
  }, [dispatch])

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const listsOffice = dataofficeAllList.listsOffice.map((item) => {return {value: item.value, label: item.label}})

  const datacsmSave = useSelector(state => state.csmSave)
  const { validation } = datacsmSave

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(csmSave(name, email, phone, gender, joindate,password,retype_password,id_office, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create CSM</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <div className="form-group d-flex flex-column">
                      <label for="name">Office</label>
                      <Select
                        showSearch
                        placeholder="Select an office"
                        optionFilterProp="children"
                        onChange={(value) => setoffice(value)}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={listsOffice}
                        value={id_office}
                      />
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['id_office']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>
                  </div>
                  
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label for="name">Gender</label>
                      <select 
                        className={ validation.length !== 0 && validation['gender'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setgender(e.target.value)}
                      >
                        <option disabled selected value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['gender']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">

                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Password</label>
                      <input type="password" 
                        className={ validation.length !== 0 && validation['password'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['password']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Retype Password</label>
                      <input type="password" 
                        className={ validation.length !== 0 && validation['retype_password'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setretype_password(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['retype_password']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSMCreate
