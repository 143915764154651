//=================================  Office  ============================================

export const OFFICE_LIST_REQUEST = 'OFFICE_LIST_REQUEST'
export const OFFICE_LIST_SUCCESS = 'OFFICE_LIST_SUCCESS'
export const OFFICE_LIST_FAIL = ' OFFICE_LIST_FAIL'

export const OFFICE_SAVE_REQUEST = 'OFFICE_SAVE_REQUEST'
export const OFFICE_SAVE_SUCCESS = 'OFFICE_SAVE_SUCCESS'
export const OFFICE_SAVE_FAIL = ' OFFICE_SAVE_FAIL'
export const OFFICE_SAVE_VALIDATION = ' OFFICE_SAVE_VALIDATION'

export const OFFICE_EDIT_REQUEST = 'OFFICE_EDIT_REQUEST'
export const OFFICE_EDIT_SUCCESS = 'OFFICE_EDIT_SUCCESS'
export const OFFICE_EDIT_FAIL = ' OFFICE_EDIT_FAIL'

export const OFFICE_UPDATE_REQUEST = 'OFFICE_UPDATE_REQUEST'
export const OFFICE_UPDATE_SUCCESS = 'OFFICE_UPDATE_SUCCESS'
export const OFFICE_UPDATE_FAIL = ' OFFICE_UPDATE_FAIL'
export const OFFICE_UPDATE_VALIDATION = ' OFFICE_UPDATE_VALIDATION'

export const OFFICE_DELETE_REQUEST = 'OFFICE_DELETE_REQUEST'
export const OFFICE_DELETE_SUCCESS = 'OFFICE_DELETE_SUCCESS'
export const OFFICE_DELETE_FAIL = ' OFFICE_DELETE_FAIL'