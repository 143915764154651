//=================================  DEVELOPER  ============================================

export const DEVELOPER_LIST_REQUEST = 'DEVELOPER_LIST_REQUEST'
export const DEVELOPER_LIST_SUCCESS = 'DEVELOPER_LIST_SUCCESS'
export const DEVELOPER_LIST_FAIL = 'DEVELOPER_LIST_FAIL'


export const DEVELOPER_ALL_REQUEST = 'DEVELOPER_ALL_REQUEST'
export const DEVELOPER_ALL_SUCCESS = 'DEVELOPER_ALL_SUCCESS'
export const DEVELOPER_ALL_FAIL = 'DEVELOPER_ALL_FAIL'

export const DEVELOPER_SAVE_REQUEST = 'DEVELOPER_SAVE_REQUEST'
export const DEVELOPER_SAVE_SUCCESS = 'DEVELOPER_SAVE_SUCCESS'
export const DEVELOPER_SAVE_FAIL = 'DEVELOPER_SAVE_FAIL'
export const DEVELOPER_SAVE_VALIDATION = 'DEVELOPER_SAVE_VALIDATION'

export const DEVELOPER_EDIT_REQUEST = 'DEVELOPER_EDIT_REQUEST'
export const DEVELOPER_EDIT_SUCCESS = 'DEVELOPER_EDIT_SUCCESS'
export const DEVELOPER_EDIT_FAIL = 'DEVELOPER_EDIT_FAIL'

export const DEVELOPER_UPDATE_REQUEST = 'DEVELOPER_UPDATE_REQUEST'
export const DEVELOPER_UPDATE_SUCCESS = 'DEVELOPER_UPDATE_SUCCESS'
export const DEVELOPER_UPDATE_FAIL = 'DEVELOPER_UPDATE_FAIL'
export const DEVELOPER_UPDATE_VALIDATION = 'DEVELOPER_UPDATE_VALIDATION'

export const DEVELOPER_DELETE_REQUEST = 'DEVELOPER_DELETE_REQUEST'
export const DEVELOPER_DELETE_SUCCESS = 'DEVELOPER_DELETE_SUCCESS'
export const DEVELOPER_DELETE_FAIL = 'DEVELOPER_DELETE_FAIL'