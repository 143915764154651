import React, { useEffect, useState } from 'react';
import { message, Modal, Input, Select, Upload, DatePicker } from 'antd';
import { asyncEditPromo } from '../../../store/actions/promoActions';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import MyCustomUploadAdapterPlugin from '../../../components/UploadFileCK';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const { TextArea } = Input;

function PromoEdit({ editedPromoData, setEditedPromoData, showModalEditPromo, setShowEditModalPromo, dataPromoCategoryAsset, dispatch }) {
  console.log(editedPromoData)
  const [loading, setloading] = useState(false);
  const [image, setimage] = useState(null);

  // form promo
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [file, setfile] = useState([]);
  const [summary, setSummary] = useState('');
  const [promoStartDate, setPromoStartDate] = useState('');
  const [promoEndDate, setPromoEndDate] = useState('');

  useEffect(() => {
    setTitle(editedPromoData.title);
    setCategory(String(editedPromoData.category_id));
    setContent(editedPromoData.content);
    setimage('https://apiv2.loanmarket.co.id/img/promo/'+editedPromoData.cover);
    setSummary(editedPromoData.summary);
    setPromoStartDate(editedPromoData.start_date);
    setPromoEndDate(editedPromoData.end_date);
  }, [])

  function beforeUpload(file) {
    setloading(true)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    setfile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setimage(imageUrl),
      setloading(false)
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCloseModalPromo = () => {
    setShowEditModalPromo(false);
    setEditedPromoData(null);
  }

  const handleEditPromo = async () => {
    await dispatch(asyncEditPromo(editedPromoData.id, title, category, content, file, summary, promoStartDate, promoEndDate));
    setShowEditModalPromo(false);
  }

  return (
    <Modal width={600} title="Edit Promo" okText={'Update'} open={showModalEditPromo} onCancel={handleCloseModalPromo} onOk={handleEditPromo} >
        <div className='row'>
          <div className='col-12 mb-2'>
            <label>Title</label>
            <Input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className='col-12 mb-2'>
            <label>Summary</label>
            <TextArea placeholder="Summary" value={summary} onChange={(e) => setSummary(e.target.value)} />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 mb-2'>
            <label>Content</label>
            <CKEditor
              editor={ ClassicEditor }
              data={editedPromoData.content}
              config={{
                extraPlugins: [ MyCustomUploadAdapterPlugin ],
              }}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setContent(data)
              } }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6 mb-2 d-flex flex-column'>
            <label>Promo Start</label>
            <input type="date"
              value={promoStartDate}
              className='form-control'
              onChange={(e) => setPromoStartDate(e.target.value)}
            />
          </div>
          <div className='col-6 mb-2 d-flex flex-column'>
            <label>Promo End</label>
            <input type="date"
              value={promoEndDate}
              className='form-control'
              onChange={(e) => setPromoEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 d-flex flex-column'>
            <label>Category</label>
            <Select
              showSearch
              className='mb-2'
              placeholder="Category"
              options={Object.keys(dataPromoCategoryAsset).map(value => {
                return {
                  value: value,
                  label: dataPromoCategoryAsset[value]
                }
              })} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={category} 
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className='col-12 mb-2'>
            <label>Cover Image</label>
            <Upload
              name="cover iamge"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            <p>*Note: Pastikan file Image 720 x 350</p>
          </div>
        </div>
      </Modal>
  )
}

export default PromoEdit