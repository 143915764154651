import { message } from 'antd'
import { CAREER_DELETE_FAIL, CAREER_DELETE_REQUEST, CAREER_DELETE_SUCCESS, CAREER_EDIT_FAIL, CAREER_EDIT_REQUEST, CAREER_EDIT_SUCCESS, CAREER_LIST_FAIL, CAREER_LIST_REQUEST, CAREER_LIST_SUCCESS, CAREER_SAVE_FAIL, CAREER_SAVE_REQUEST, CAREER_SAVE_SUCCESS, CAREER_SAVE_VALIDATION, CAREER_UPDATE_FAIL, CAREER_UPDATE_REQUEST, CAREER_UPDATE_SUCCESS, CAREER_UPDATE_VALIDATION, NEWS_DELETE_FAIL, NEWS_DELETE_REQUEST, NEWS_DELETE_SUCCESS, NEWS_EDIT_FAIL, NEWS_EDIT_REQUEST, NEWS_EDIT_SUCCESS, NEWS_LIST_FAIL, NEWS_LIST_REQUEST, NEWS_LIST_SUCCESS, NEWS_SAVE_FAIL, NEWS_SAVE_REQUEST, NEWS_SAVE_SUCCESS, NEWS_SAVE_VALIDATION, NEWS_UPDATE_FAIL, NEWS_UPDATE_REQUEST, NEWS_UPDATE_SUCCESS, NEWS_UPDATE_VALIDATION, PARTNER_DELETE_FAIL, PARTNER_DELETE_REQUEST, PARTNER_DELETE_SUCCESS, PARTNER_EDIT_FAIL, PARTNER_EDIT_REQUEST, PARTNER_EDIT_SUCCESS, PARTNER_LIST_FAIL, PARTNER_LIST_REQUEST, PARTNER_LIST_SUCCESS, PARTNER_SAVE_FAIL, PARTNER_SAVE_REQUEST, PARTNER_SAVE_SUCCESS, PARTNER_SAVE_VALIDATION, PARTNER_UPDATE_FAIL, PARTNER_UPDATE_REQUEST, PARTNER_UPDATE_SUCCESS, PARTNER_UPDATE_VALIDATION, TOP_PERFORMER_DELETE_FAIL, TOP_PERFORMER_DELETE_REQUEST, TOP_PERFORMER_DELETE_SUCCESS, TOP_PERFORMER_EDIT_FAIL, TOP_PERFORMER_EDIT_REQUEST, TOP_PERFORMER_EDIT_SUCCESS, TOP_PERFORMER_LIST_FAIL, TOP_PERFORMER_LIST_REQUEST, TOP_PERFORMER_LIST_SUCCESS, TOP_PERFORMER_SAVE_FAIL, TOP_PERFORMER_SAVE_REQUEST, TOP_PERFORMER_SAVE_SUCCESS, TOP_PERFORMER_SAVE_VALIDATION, TOP_PERFORMER_UPDATE_FAIL, TOP_PERFORMER_UPDATE_REQUEST, TOP_PERFORMER_UPDATE_SUCCESS, TOP_PERFORMER_UPDATE_VALIDATION } from '../constants/websiteConstants';

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  News  ============================================

export const newsListReducer = (state = {news:[], listsNews:[]}, action) => {
  switch (action.type) {
    case NEWS_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case NEWS_LIST_SUCCESS:
      return{ ...state, loading: false, news: action.payload.data, listsNews: action.payload.data.data }
    case NEWS_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const newsSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case NEWS_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case NEWS_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case NEWS_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const newsEditReducer = (state = {news:{}}, action) => {
  switch (action.type) {
    case NEWS_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case NEWS_EDIT_SUCCESS:
      return{ ...state, loading: false, news: action.payload.data }
    case NEWS_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const newsUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case NEWS_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case NEWS_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case NEWS_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const newsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case NEWS_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case NEWS_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Partner  ============================================

export const partnerListReducer = (state = {partner:[], listsPartner:[]}, action) => {
  switch (action.type) {
    case PARTNER_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARTNER_LIST_SUCCESS:
      return{ ...state, loading: false, partner: action.payload.data, listsPartner: action.payload.data.data }
    case PARTNER_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const partnerSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case PARTNER_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case PARTNER_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case PARTNER_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const partnerEditReducer = (state = {partner:{}}, action) => {
  switch (action.type) {
    case PARTNER_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case PARTNER_EDIT_SUCCESS:
      return{ ...state, loading: false, partner: action.payload.data }
    case PARTNER_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const partnerUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case PARTNER_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case PARTNER_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case PARTNER_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const partnerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case PARTNER_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case PARTNER_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Top Performer  ============================================

export const topperformerListReducer = (state = {topperformer:[], listsTopperformer:[]}, action) => {
  switch (action.type) {
    case TOP_PERFORMER_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case TOP_PERFORMER_LIST_SUCCESS:
      return{ ...state, loading: false, topperformer: action.payload.data, listsTopperformer: action.payload.data.data }
    case TOP_PERFORMER_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const topperformerSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOP_PERFORMER_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case TOP_PERFORMER_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case TOP_PERFORMER_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case TOP_PERFORMER_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const topperformerEditReducer = (state = {topperformer:{}}, action) => {
  switch (action.type) {
    case TOP_PERFORMER_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case TOP_PERFORMER_EDIT_SUCCESS:
      return{ ...state, loading: false, topperformer: action.payload.data }
    case TOP_PERFORMER_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const topperformerUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOP_PERFORMER_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case TOP_PERFORMER_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case TOP_PERFORMER_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case TOP_PERFORMER_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const topperformerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOP_PERFORMER_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case TOP_PERFORMER_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case TOP_PERFORMER_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Career  ============================================

export const careerListReducer = (state = {career:[], listsCareer:[]}, action) => {
  switch (action.type) {
    case CAREER_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case CAREER_LIST_SUCCESS:
      return{ ...state, loading: false, career: action.payload.data, listsCareer: action.payload.data.data }
    case CAREER_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const careerSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAREER_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case CAREER_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case CAREER_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case CAREER_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const careerEditReducer = (state = {career:{}}, action) => {
  switch (action.type) {
    case CAREER_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case CAREER_EDIT_SUCCESS:
      return{ ...state, loading: false, career: action.payload.data }
    case CAREER_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const careerUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAREER_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case CAREER_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case CAREER_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case CAREER_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const careerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CAREER_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case CAREER_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case CAREER_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}