import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { roleAllList } from '../../../../store/actions/menuActions'
import { userSave } from '../../../../store/actions/authActions'

const UserCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [roles, setrole] = useState('')

  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(roleAllList('MY'))
  }, [dispatch])

  const dataroleAllList = useSelector(state => state.roleAllList)
  const { role } = dataroleAllList

  const datauserSave = useSelector(state => state.userSave)
  const { validation } = datauserSave

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userSave(name, roles, email, password, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create User</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                    name="name" 
                    required 
                    onChange={(e) => setname(e.target.value)}
                  />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['name']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" 
                    className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                    name="email_input" 
                    required 
                    onChange={(e) => setemail(e.target.value)}
                  />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['email']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" 
                    className={ validation.length !== 0 && validation['password'] ? 'form-control parsley-error' : 'form-control' }
                    name="password_input" 
                    required 
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['password']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label>Role</label>
                  <select 
                    className={ validation.length !== 0 && validation['role'] ? 'form-control parsley-error' : 'form-control' }
                    required onChange={(e) => setrole(e.target.value)}>
                    <option disabled selected value="">Select Role</option>
                    {
                      role.map((item, index) => {
                        return(
                          <option key={index} value={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['role']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCreate
